<template>
  <v-container>
    <v-toolbar style="margin-bottom:1em">
      <v-toolbar-title>Mailroom Settings</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog width="400">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon color="info">fas fa-question-circle</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Settings Help</v-card-title>
          <v-card-text>
            <p>Below are the different actions that can be done on this page and a brief explanation on how to do it.</p>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Add a new item</v-list-item-title>
                  <v-list-item-subtitle style="white-space:normal">Enter text in the box above the list and press enter</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Remove an item</v-list-item-title>
                  <v-list-item-subtitle style="white-space:normal">Click the red trash can</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Move an item</v-list-item-title>
                  <v-list-item-subtitle style="white-space:normal">Click and hold on the name of the item and drag it to its new location</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Edit an item's text</v-list-item-title>
                  <v-list-item-subtitle style="white-space:normal">You cannot edit items. Instead, add a new entry with the text you want, drag it to where you want it, and remove the old one</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <span>Click off this card to close it</span>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-row>
      <v-col :cols="12" md="4">
        <v-card>
          <v-card-title>Package Types</v-card-title>
          <v-list dense>
            <draggable v-model="typeOptions" @end="update('packageType')">
              <v-list-item v-for="({ text }, index) in typeOptions" :key="'type-' + index">
                <v-list-item-title>{{ text }}</v-list-item-title>
                <v-list-item-action>
                  <v-btn icon small @click="remove('packageType', index)">
                    <v-icon color="error" small>fal fa-trash-alt</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <template v-slot:header>
                <v-text-field v-model="addPackageType" label="Add Package Type" hint="Enter text and press enter to add" outlined @keydown.enter="add('packageType', addPackageType)"></v-text-field>
              </template>
            </draggable>
          </v-list>
        </v-card>
      </v-col>
      <v-col :cols="12" md="4">
        <v-card>
          <v-card-title>Locations</v-card-title>
          <v-list dense>
            <draggable v-model="locationOptions" @end="update('location')">
              <v-list-item v-for="({ text }, index) in locationOptions" :key="'location-' + index">
                <v-list-item-title>{{ text }}</v-list-item-title>
                <v-list-item-action>
                  <v-btn icon small @click="remove('location', index)">
                    <v-icon color="error" small>fal fa-trash-alt</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <template v-slot:header>
                <v-text-field v-model="addLocation" label="Add Location" hint="Enter text and press enter to add" outlined @keydown.enter="add('location', addLocation)"></v-text-field>
              </template>
            </draggable>
          </v-list>
        </v-card>
      </v-col>
      <v-col :cols="12" md="4">
        <v-card>
          <v-card-title>Status Options</v-card-title>
          <v-list dense>
            <draggable v-model="statusOptions" @end="update('status')">
              <v-list-item v-for="({ text }, index) in statusOptions" :key="'status-' + index">
                <v-list-item-title>{{ text }}</v-list-item-title>
                <v-list-item-action>
                  <v-btn icon small @click="remove('status', index)">
                    <v-icon color="error" small>fal fa-trash-alt</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <template v-slot:header>
                <v-text-field v-model="addStatus" label="Add Package Status" hint="Enter text and press enter to add" outlined @keydown.enter="add('status', addStatus)"></v-text-field>
              </template>
            </draggable>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { onMounted, ref, computed } from '@vue/composition-api'

export default {
  components: {
    draggable: () => import('vuedraggable')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user)
    const statusOptions = ref([])
    const statusOptionId = ref('')
    const locationOptions = ref([])
    const locationOptionId = ref('')
    const typeOptions = ref([])
    const typeOptionId = ref('')

    const addPackageType = ref('')
    const addLocation = ref('')
    const addStatus = ref('')

    const optionService = root.$feathers.service('forms/select-options')
    onMounted(() => {
      optionService.find({ query: { name: 'Mailroom: Package Status' } }).then(({ data }) => {
        if (data.length > 0) {
          statusOptionId.value = data[0].value
          optionService.get(data[0].value).then(({ options }) => {
            statusOptions.value = options
          })
        } else {
          const options = []
          const initStatusOptions = ['Received', 'Picked Up', 'Ready for Pickup', 'Returned']
          for (let i = 0; i < initStatusOptions.length; i++) {
            options.push({ text: initStatusOptions[i], value: initStatusOptions[i] })
          }
          optionService.create({
            name: 'Mailroom: Package Status',
            type: 'static',
            details: 'Status options for mailroom packages',
            visibleRoles: ['Office Services', 'Technology Services'],
            editRole: 'Office Services',
            createdBy: user.username,
            options
          })
        }
      })
      optionService.find({ query: { name: 'Mailroom: Package Location' } }).then(({ data }) => {
        if (data.length > 0) {
          locationOptionId.value = data[0].value
          optionService.get(data[0].value).then(({ options }) => {
            locationOptions.value = options
          })
        } else {
          const options = []
          const initLocationOptions = ['Athletics Rack', 'Back Table', 'Bin', 'Employee Rack', 'Floor', 'Front Table', 'Mailbox (in their box)', 'Rack 1 (AB)', 'Rack 2 (CDE)', 'Rack 3 (FG)', 'Rack 4 (HIJK)', 'Rack 5 (LM)', 'Rack 6 (NOP)', 'Rack 7 (QR)', 'Rack 8 (ST)', 'Rack 9 (UVWXYZ)']
          for (let i = 0; i < initLocationOptions.length; i++) {
            options.push({ text: initLocationOptions[i], value: initLocationOptions[i] })
          }
          optionService.create({
            name: 'Mailroom: Package Location',
            type: 'static',
            details: 'Location options for mailroom packages',
            visibleRoles: ['Office Services', 'Technology Services'],
            editRole: 'Office Services',
            createdBy: user.username,
            options
          })
        }
      })
      optionService.find({ query: { name: 'Mailroom: Package Type' } }).then(({ data }) => {
        if (data.length > 0) {
          typeOptionId.value = data[0].value
          optionService.get(data[0].value).then(({ options }) => {
            typeOptions.value = options
          })
        } else {
          const options = []
          const initTypeOptions = ['Box (Large)', 'Box (Small)', 'Next Day Air Envelope', 'Package', 'Padded Envelope', 'Tube']
          for (let i = 0; i < initTypeOptions.length; i++) {
            options.push({ text: initTypeOptions[i], value: initTypeOptions[i] })
          }
          optionService.create({
            name: 'Mailroom: Package Type',
            type: 'static',
            details: 'Package type options for mailroom packages',
            visibleRoles: ['Office Services', 'Technology Services'],
            editRole: 'Office Services',
            createdBy: user.username,
            options
          })
        }
      })
    })

    function remove (type, index) {
      switch (type) {
        case 'packageType':
          typeOptions.value.splice(index, 1)
          optionService.patch(typeOptionId.value, { options: typeOptions.value })
          break
        case 'location':
          locationOptions.value.splice(index, 1)
          optionService.patch(locationOptionId.value, { options: locationOptions.value })
          break
        case 'status':
          statusOptions.value.splice(index, 1)
          optionService.patch(statusOptionId.value, { options: statusOptions.value })
          break
      }
    }

    function add (type, value) {
      switch (type) {
        case 'packageType':
          optionService.patch(typeOptionId.value, { $push: { options: { text: value, value } } })
          typeOptions.value.push({ text: value, value })
          addPackageType.value = ''
          break
        case 'location':
          optionService.patch(locationOptionId.value, { $push: { options: { text: value, value } } })
          locationOptions.value.push({ text: value, value })
          addLocation.value = ''
          break
        case 'status':
          optionService.patch(statusOptionId.value, { $push: { options: { text: value, value } } })
          statusOptions.value.push({ text: value, value })
          addStatus.value = ''
          break
      }
    }

    function update (type) {
      switch (type) {
        case 'packageType':
          optionService.patch(typeOptionId.value, { options: typeOptions.value })
          break
        case 'location':
          optionService.patch(locationOptionId.value, { options: locationOptions.value })
          break
        case 'status':
          optionService.patch(statusOptionId.value, { options: statusOptions.value })
          break
      }
    }

    return {
      statusOptions,
      locationOptions,
      typeOptions,
      addPackageType,
      addLocation,
      addStatus,
      remove,
      add,
      update
    }
  }
}
</script>
